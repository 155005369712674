const Colors = {
    text: {
        default: '#394147',
        light: '#667580',
        onDark: '#ffffff',
    },
    brand: {
        default: '#5cace2',
        hover: '#1f74ad',
        active: '#175782',
        50: '#e9f4fb',
        100: '#c2e0f4',
    },
    danger: {
        default: '#cd2026',
        background: '#fcedee',
    },
    success: {
        default: '#38a169',
    },
    info: {
        50: '#e7f1f8',
        100: '#d5e2eb',
        600: '#3e6684',
    },
    inversed: {
        default: '#ffffff',
        hover: '#ffffff99',
        active: '#ffffffcc',
    },
    background: {
        body: '#ffffff',
        dark: '#f9fafb',
        darker: '#f3f5f7',
    },
    border: {
        body: '#b8c0c6',
        card: '#d5e2eb',
    },
    gray: {
        50: '#f9fafb',
        100: '#f3f5f7',
        200: '#d5e2eb',
        300: '#b8c0c6',
        700: '#394147',
    },
    red: {
        50: '#fcedee',
        300: '#e8696d',
        500: '#cd2026',
    },
    warning: {
        100: '#f6d6c1',
        400: '#e58648',
        500: '#df6c20',
        600: '#bb561b',
    },
} as const;

export default Colors;
