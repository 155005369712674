import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /*!
   * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
   * Copyright 2015 Daniel Cardoso <@DanielCardoso>
   * Licensed under MIT
   */
  .laBallSpinClockwise,
  .laBallSpinClockwise > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .laBallSpinClockwise {
    display: inline-block;
    font-size: 0;
    color: inherit;
  }
  .laBallSpinClockwise.laDark {
    color: #333;
  }
  .laBallSpinClockwise > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
  }
  .laBallSpinClockwise {
    width: 32px;
    height: 32px;
  }
  .laBallSpinClockwise > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;
    border-radius: 100%;
    -webkit-animation: ballSpinClockwise 1s infinite ease-in-out;
    -moz-animation: ballSpinClockwise 1s infinite ease-in-out;
    -o-animation: ballSpinClockwise 1s infinite ease-in-out;
    animation: ballSpinClockwise 1s infinite ease-in-out;
  }
  .laBallSpinClockwise > div:nth-child(1) {
    top: 5%;
    left: 50%;
    -webkit-animation-delay: -0.875s;
    -moz-animation-delay: -0.875s;
    -o-animation-delay: -0.875s;
    animation-delay: -0.875s;
  }
  .laBallSpinClockwise > div:nth-child(2) {
    top: 18.1801948466%;
    left: 81.8198051534%;
    -webkit-animation-delay: -0.75s;
    -moz-animation-delay: -0.75s;
    -o-animation-delay: -0.75s;
    animation-delay: -0.75s;
  }
  .laBallSpinClockwise > div:nth-child(3) {
    top: 50%;
    left: 95%;
    -webkit-animation-delay: -0.625s;
    -moz-animation-delay: -0.625s;
    -o-animation-delay: -0.625s;
    animation-delay: -0.625s;
  }
  .laBallSpinClockwise > div:nth-child(4) {
    top: 81.8198051534%;
    left: 81.8198051534%;
    -webkit-animation-delay: -0.5s;
    -moz-animation-delay: -0.5s;
    -o-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .laBallSpinClockwise > div:nth-child(5) {
    top: 94.9999999966%;
    left: 50.0000000005%;
    -webkit-animation-delay: -0.375s;
    -moz-animation-delay: -0.375s;
    -o-animation-delay: -0.375s;
    animation-delay: -0.375s;
  }
  .laBallSpinClockwise > div:nth-child(6) {
    top: 81.8198046966%;
    left: 18.1801949248%;
    -webkit-animation-delay: -0.25s;
    -moz-animation-delay: -0.25s;
    -o-animation-delay: -0.25s;
    animation-delay: -0.25s;
  }
  .laBallSpinClockwise > div:nth-child(7) {
    top: 49.9999750815%;
    left: 5.0000051215%;
    -webkit-animation-delay: -0.125s;
    -moz-animation-delay: -0.125s;
    -o-animation-delay: -0.125s;
    animation-delay: -0.125s;
  }
  .laBallSpinClockwise > div:nth-child(8) {
    top: 18.179464974%;
    left: 18.1803700518%;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }
  .laBallSpinClockwise.laXsm {
    width: 10px;
    height: 10px;
  }
  .laBallSpinClockwise.laXsm > div {
    width: 3px;
    height: 3px;
    margin-top: -2px;
    margin-left: -2px;
  }
  .laBallSpinClockwise.laSm {
    width: 14px;
    height: 14px;
  }
  .laBallSpinClockwise.laSm > div {
    width: 4px;
    height: 4px;
    margin-top: -2px;
    margin-left: -2px;
  }
  .laBallSpinClockwise.la2x {
    width: 64px;
    height: 64px;
  }
  .laBallSpinClockwise.la2x > div {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
  }
  .laBallSpinClockwise.la3x {
    width: 96px;
    height: 96px;
  }
  .laBallSpinClockwise.la3x > div {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
  }
  /*
   * Animation
   */
  @-webkit-keyframes ballSpinClockwise {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  @-moz-keyframes ballSpinClockwise {
    0%,
    100% {
      opacity: 1;
      -moz-transform: scale(1);
      transform: scale(1);
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      -moz-transform: scale(0);
      transform: scale(0);
    }
  }
  @-o-keyframes ballSpinClockwise {
    0%,
    100% {
      opacity: 1;
      -o-transform: scale(1);
      transform: scale(1);
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      -o-transform: scale(0);
      transform: scale(0);
    }
  }
  @keyframes ballSpinClockwise {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
    }
  }
`;

const SpinnerWrap = styled.div`
    display: inline-block;
`;

const Spinner = ({ className, customSize }) => {
    let customClasses = 'laBallSpinClockwise';
    let size = 'laSm';

    if (typeof customSize !== 'undefined') {
        size = customSize;
    }

    return (
        <>
            <GlobalStyle />
            <SpinnerWrap className={className}>
                <div className={customClasses + ' ' + size}>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </SpinnerWrap>
        </>
    );
};

export default Spinner;
