// @todo: verify and make sure we use the same wording for the services as we do on the API side of things.

const homeDogBoarding = 'petBoarding';
const doggyDayCare = 'doggyDayCare';
const dogWalking = 'dogWalking';
const homeVisits = 'homeVisits';
const catSitting = 'catSitting';
const dogSitting = 'dogSitting';
const petSitting = 'petSitting';
const houseSitting = 'sleepover';

const homeDogBoardingService = {
    id: homeDogBoarding,
    visible: true,
    synonyms: [],
};
const doggyDayCareService = {
    id: doggyDayCare,
    visible: true,
    synonyms: [],
};
const dogWalkingService = {
    id: dogWalking,
    visible: true,
    synonyms: [],
};
const homeVisitsService = {
    id: homeVisits,
    visible: true,
    synonyms: [catSitting, dogSitting, petSitting],
    additionalServices: [
        {
            id: 'secondDailyVisit',
            visible: true,
        },
    ],
};
const catSittingService = {
    id: catSitting,
    visible: false,
    synonyms: [],
};
const dogSittingService = {
    id: dogSitting,
    visible: false,
    synonyms: [],
};
const petSittingService = {
    id: petSitting,
    visible: false,
    synonyms: [],
};
const houseSittingService = {
    id: houseSitting,
    visible: true,
    synonyms: [],
};

const getServiceBasedUponId = (id) => {
    switch (id) {
        case homeDogBoarding:
            return homeDogBoardingService;
        case doggyDayCare:
            return doggyDayCareService;
        case dogWalking:
            return dogWalkingService;
        case homeVisits:
            return homeVisitsService;
        case catSitting:
            return catSittingService;
        case dogSitting:
            return dogSittingService;
        case petSitting:
            return petSittingService;
        case houseSitting:
            return houseSittingService;
    }
};

const allServices = [
    homeDogBoardingService,
    doggyDayCareService,
    dogWalkingService,
    homeVisitsService,
    catSittingService,
    dogSittingService,
    petSittingService,
    houseSittingService,
];

const list = [homeDogBoarding, doggyDayCare, dogWalking, homeVisits, catSitting, dogSitting, petSitting, houseSitting];

module.exports = {
    homeDogBoarding,
    doggyDayCare,
    dogWalking,
    homeVisits,
    catSitting,
    dogSitting,
    petSitting,
    houseSitting,
    list,
    allServices,
    homeDogBoardingService,
    doggyDayCareService,
    dogWalkingService,
    homeVisitsService,
    catSittingService,
    dogSittingService,
    petSittingService,
    houseSittingService,
    getServiceBasedUponId,
};
