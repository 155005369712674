import React, { forwardRef } from 'react';
import styled from 'styled-components';

const btn = styled.button``;

const linkButton = styled.button`
    background-color: transparent;
    color: #00afed;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const link = styled.a`
    padding: 10px;
    touch-action: manipulation;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    vertical-align: middle;
    position: relative;
    margin: 0;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 4px;
`;

type NativeButtonProps = React.HTMLProps<HTMLButtonElement>;

interface ButtonProps extends NativeButtonProps {
    isLinkButton?: boolean;
    children?: any;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { isLinkButton, href, target, disabled, children, onClick, style, className } = props;

    let element = href ? link : btn;

    if (isLinkButton) {
        element = linkButton;
    }

    const elementProps = {
        href,
        ref,
        target,
        onClick,
        style,
        className,
        disabled,
    };

    return React.createElement(element, elementProps, children);
});

export default Button;
