import { LazyLoadImage } from 'react-lazy-load-image-component';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Button from '../components/Common/Button';
import FakeInput from '../components/Common/FakeInput';
import Spinner from '../components/Common/Spinner';
import Colors from './colors';

export const CircleAvatar = styled.img`
    border-radius: 50%;
    margin-right: 1rem;
`;

export const HeroHeader = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    margin-bottom: 3rem;

    ${breakpoint('md')`
    text-align: center;
    margin-top: 3rem;
  `};
`;

export const InlineImage = styled(LazyLoadImage)`
    object-fit: cover;
    max-width: 100%;
    height: 300px;
    border-radius: 4px;
    flex-shrink: 0;
    object-fit: contain;
`;

export const Textarea = styled(TextareaAutosize)`
    border-color: #cecece;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    max-width: 100%;
    resize: none;
    box-sizing: border-box;
    margin-bottom: 1rem;
`;

export const PageTitle = styled.h1`
    font-weight: 400;
    font-size: 2rem;
    color: #4f4f4f;
    margin-bottom: 1rem;
`;

export const PageSubTitle = styled.h2`
    line-height: 1.5rem;
    font-weight: 400;
    font-size: 1.3rem;
    color: #4f4f4f;
    margin-bottom: 2rem;
`;

export const PageIntroduction = styled.p`
    line-height: 1.5rem;
`;

export const SearchPageLink = styled.a`
    margin-right: 1rem;
    margin-bottom: 1rem;

    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-sizing: border-box;

    width: calc(100% - 1rem);
    ${breakpoint('md')`width: calc(50% - 1rem);`};
    ${breakpoint('lg')`width: calc(33.33% - 1rem);`};
`;

export const BoxedLinkGroup = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

export const ServiceIcon = styled.img`
    opacity: 0.63;
    margin-right: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    line-height: 40px;

    @media (min-width: 768px) {
        line-height: 24px;
    }

    width: 1.5rem;
`;

export const GroupName = styled.h3`
    line-height: 30px;
    width: 100%;
    margin-bottom: 1rem;
`;

export const ServiceLinks = styled.div`
    ${breakpoint('md')`
    margin-bottom: 3rem;
  `};
`;

export const ServiceWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
`;

export const InlineSpinner = styled(Spinner)`
    margin-right: 1rem;
`;

export const SettingsSection = styled.div`
    &:not(:first-child) {
        margin: 1rem 0;
    }
`;

export const SettingsTitle = styled.div`
    color: #747474;
    font-weight: 500;
    margin-bottom: 0.25rem;
`;

export const Label = styled.span`
    display: block;
    color: #939393;
    line-height: 1rem;
    margin-bottom: 1rem;
`;

export const FlexedLabel = styled(Label)`
    display: flex;
    align-items: center;
`;

export const Input = styled.input`
    display: block;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    :read-only {
        background-color: #f4f4f4;
        cursor: not-allowed;

        :hover {
            border-color: #cecece;
            outline: none;
        }

        :focus {
            border-color: #cecece;
            outline: none;
        }
    }
`;

export const GreenLabel = styled(Label)`
    color: #00c38a;
`;

export const GreenButton = styled(Button)`
    border: 1px solid #00c38a;
    border-radius: 4px;
    background-color: #00c38a;
    touch-action: manipulation;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
        background-color: #cecece;
        border-color: #f4f4f4;
        pointer-events: none;
    }
    padding: 10px;
`;

/**
 * New green button, introduced by Sven 07 APR 2023
 * Main reason for the new button: don't impact existing ones,
 * but replace them 1 by 1 */
export const NewGreenButton = styled(Button)`
    border: 2px solid #00c38a;
    border-radius: 8px;
    background-color: #00c38a;
    touch-action: manipulation;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    padding: 0.5rem 1.5rem;
    width: fit-content;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 1.5rem;

    :hover {
        background: #00996c;
        border-color: white;
    }
    :active {
        background: #006648;
        border-color: white;
    }
    :disabled {
        background-color: #cecece;
        border-color: #f4f4f4;
        pointer-events: none;
    }

    & > img {
        margin-right: 0.5rem;
    }
`;

export const NewGreenButtonFullWidth = styled(NewGreenButton)`
    width: 100%;
`;

export const RedButton = styled(Button)`
    border: 1px solid #eb4c52;
    border-radius: 4px;
    background-color: #eb4c52;
    touch-action: manipulation;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
        background-color: #cecece;
        border-color: #eb4c52;
        pointer-events: none;
    }
    padding: 10px;
`;

export const HollowBlueButton = styled(Button)`
    border-radius: 4px;
    touch-action: manipulation;
    display: block;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: white;
    border: 1px solid #00afed;
    color: #00afed;
    padding: 10px;
`;

export const ColoredButton = styled(Button)`
    border: 1px solid ${(props) => props.color};
    border-radius: 4px;
    background-color: ${(props) => props.color};
    touch-action: manipulation;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
        background-color: #cecece;
        border-color: #f4f4f4;
        pointer-events: none;
    }
    padding: 10px;
`;

export const ConsecutiveDatesFilter = styled(FakeInput)`
    background: #ffffff url('https://static1.pawshakecdn.com/icons/icon-date-separator.svg') no-repeat center;
`;

export const ConsecutiveDatesFilterFrom = styled.div`
    float: left;
`;

export const ConsecutiveDatesFilterTill = styled.div`
    float: right;
`;

export const TwoColumnLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;

    h2 {
        margin-bottom: 2rem;
    }
`;

export const Column = styled.div`
    flex: 1;
    margin-right: 2rem;
`;

export const StyledLabel = styled(Label)`
    margin-bottom: 1rem;
`;

export const StyledGreenButton = styled(GreenButton)`
    margin-top: 2rem;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

export const Right = styled.div`
    margin-left: 5rem;
    text-align: right;
`;

export const Green = styled.div`
    color: #00c38a;
`;

export const Red = styled.div`
    color: #eb4c52;
`;

export const ContainerHeader = styled.div`
    border: 1px solid ${Colors.border.card};
    border-bottom: none;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: ${Colors.background.dark};
    color: ${Colors.text.default};
    padding: 1rem;
    display: flex;
    justify-content: space-between;
`;

export const ContainerBody = styled.div`
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid ${Colors.border.card};
    padding: 1rem;
`;

export const SavingIndicatorContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CenteredDiv = styled.div`
    margin-left: auto;
    margin-right: auto;
`;

export const Description = styled.div`
    color: #939393;
    font-size: 0.9rem;
`;
